<template>
    <div class="row my-2">
        <div class="col-md-5">
            {{ title }}
        </div>
        <div class="col-md-7">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: { title: { type: String, default: "" } },
}
</script>

<style></style>
