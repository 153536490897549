<template>
    <div v-if="users">
        <span :key="user._id" v-for="(user, i) of users">
            <UserHoverDetail :user="user" />
            <span v-if="i != users.length - 1">, </span>
        </span>
    </div>
</template>

<script>
import UserHoverDetail from "./UserHoverDetail.vue"

export default {
    components: { UserHoverDetail },
    props: {
        users: {
            type: Array,
            default: [],
        },
    },
}
</script>

<style></style>
