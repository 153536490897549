<template>
    <div>
        <h4 class="group-heading" @click="showMenu = !showMenu">
            {{ sidebarLinkGroup.title }}
            <i :class="[{ 'fas fa-chevron-down': !showMenu }, { 'fas fa-chevron-up': showMenu }]" />
        </h4>
        <div v-if="showMenu">
            <template v-for="sidebarLink of sidebarLinkGroup.data">
                <a
                    v-if="sidebarLink.forceReload"
                    :class="[
                        'p-3 block outline-none hover:text-blue-600',
                        { 'bg-blue-300 text-blue': $route.path === sidebarLink.link },
                        { ' hover:bg-blue-100': $route.path !== sidebarLink.link },
                    ]"
                    style="color: white"
                    :key="sidebarLink.link"
                    :href="sidebarLink.link"
                    >{{ sidebarLink.title }}
                </a>
                <router-link
                    v-else
                    :class="[
                        'p-3 block outline-none hover:text-blue-600',
                        { 'bg-blue-300 text-blue': $route.path === sidebarLink.link },
                        { ' hover:bg-blue-100': $route.path !== sidebarLink.link },
                    ]"
                    style="color: white"
                    :key="sidebarLink.link"
                    :to="sidebarLink.link"
                    >{{ sidebarLink.title }}</router-link
                >
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        sidebarLinkGroup: { type: Object, default: () => ({}) },
    },
    data() {
        return { showMenu: true }
    },
}
</script>

<style scoped>
.group-heading {
    padding-top: 25px;
    padding: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #aaa;
    color: white;
}
.group-heading:hover {
    color: white;
}
</style>
