
import "material-design-icons-iconfont"
import Form from "./form.vue"
import { http } from "../../utils/http"
import "vue-good-table/dist/vue-good-table.css"

// import { VueGoodTable } from 'vue-good-table';
import HeaderSearch from "../UI/HeaderSearch.vue"
import AppBtn from "../UI/AppBtn.vue"
import CustomTable from "./CustomTable.vue"

export default {
    emits: ["created", "formDataUpdated"],
    components: { CustomTable, AppBtn, HeaderSearch, Form },
    data() {
        return {
            dataItems: [],
            search: "",
            name: "",
            showForm: false,
            initialized: false,
            processing: false,
            columns: [],
            loadingCrud: false,
            editMode: false,
            editData: null,
        }
    },
    props: {
        columnCount: { type: Number, default: 2 },
        dataItemsFilterKey: {
            type: String,
            default: "name",
        },
        onlyCreate: {
            type: Boolean,
            default: false,
        },
        showTableData: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        editingFormatter: {
            type: Function,
            default: (data) => {
                return data
            },
        },
        allowGoBack: {
            type: Boolean,
            default: false,
        },
        onlyEdit: {
            type: Boolean,
            default: false,
        },
        onlyEditData: {
            type: Object,
            default: () => null,
        },
        editRouteName: { type: String, default: "" },
        createFields: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: "",
        },
        fetchUrl: {
            type: String,
            default: "",
        },
        createUrl: {
            type: String,
            default: "",
        },
        updateRootUrl: {
            type: String,
            default: "",
        },
        deleteRootUrl: {
            type: String,
            default: "",
        },
        viewColumns: {
            type: Array,
            default: () => [
                // {
                //     label: "Id",
                //     field: "_id",
                //     type: "number",
                // },
                // {
                //     label: "Name",
                //     field: "name",
                // },
            ],
        },
    },
    mounted() {
        this.columns = this.viewColumns
        if (this.onlyEdit) {
            this.enableEditing(this.onlyEditData)
        } else {
            this.fetchData()
        }
    },
    methods: {
        navigatePage(routeObj) {
            window.location.href = this.$router.resolve(routeObj).href
        },
        async enableCreateForm() {
            this.editData = null

            // for new state
            const showFormOriginal = this.showForm
            this.showForm = false

            setTimeout(() => {
                this.showForm = showFormOriginal
                if (this.showForm && !this.editMode) {
                    this.showForm = false
                } else {
                    this.showForm = true
                }
                this.editMode = false
            }, 0)
        },
        async enableEditing(editItem) {
            this.disableEditing()

            setTimeout(() => {
                this.editMode = true
                this.editData = this.editingFormatter(editItem)
                this.showForm = true
            }, 0)
            window.scroll({ top: 0, behavior: "smooth" })
        },
        async disableEditing(cancelMode) {
            window.scroll({ top: 0, behavior: "smooth" })
            if (this.onlyEdit) {
                if (this.allowGoBack && cancelMode) {
                    this.$router.go(-1)
                }
            } else {
                this.editMode = false
                this.editData = null
                this.showForm = false
            }
        },
        async handleCreateSuccess(result) {
            if (this.onlyEdit) {
                if (this.allowGoBack) {
                    this.$router.go(-1)
                }
                return
            }
            if (this.editMode) {
                this.dataItems = this.dataItems.map((item) => {
                    if (item._id == result._id) {
                        return result
                    }
                    return item
                })
            } else {
                this.dataItems.push(result)
            }
            this.$emit("created", result)
            this.disableEditing()
        },
        async fetchData() {
            this.loadingCrud = true
            try {
                const res = await http.get(this.fetchUrl)
                this.dataItems = res.data.result
            } catch (err) {
                console.log("error in loading data", err)
            }
            this.initialized = true
            this.loadingCrud = false
        },
        async deleteDataItem(id) {
            try {
                if (confirm("Do you really want to delete?")) {
                    await http.delete(`${this.deleteRootUrl}/${id}`)
                    this.$toast.info(this.title + " Deleted Successfully!")
                    this.dataItems = this.dataItems.filter((item) => item._id !== id)
                    this.disableEditing()
                }
            } catch (err) {
                console.log("error in deleting " + this.title + " item", err)
                this.$toast.error("Some error occured")
            }
        },
    },
    computed: {
        loadingInfo() {
            return this.loading || this.loadingCrud
        },
        dataItemsFiltered() {
            return this.dataItems
                ? this.dataItems.filter((item) =>
                      item[this.dataItemsFilterKey] ? item[this.dataItemsFilterKey].toLowerCase().includes(this.search.toLowerCase()) : true
                  )
                : []
        },
    },
}
