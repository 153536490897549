<template>
    <div class="d-flex flex-col items-center">
        <img class="loading-icon" src="@/assets/icons/loading_icon.png" />
        <div class="text-center primary-color">{{ text }}</div>
    </div>
</template>

<script>
export default {
    props: {
        text: { type: String, default: "Please wait..." },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.primary-color {
    color: $primaryTheme;
}
.loading-icon {
    width: 80px;
    margin: 15px 0;
}
</style>
