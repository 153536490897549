<template>
    <div>
        <div class="p-3 d-flex flex-col justify-content-center" style="height: 100vh" v-if="!authInitialized">
            <div class=""><Spinner /></div>
        </div>
        <LoginPage v-else-if="!authenticated" />
        <div v-else>
            <div class="main-bg w-full" :style="`min-height: 100vh; background: ${authenticated ? 'white' : '#0847A6'}`">
                <div class="row">
                    <Sidebar v-if="authenticated" />
                </div>
                <div class="main-view" v-if="authenticated">
                    <div class="main-view-inner">
                        <router-view />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import Sidebar from "./components/layout/Sidebar.vue"
import Header from "./components/layout/Header.vue"
import LoginForm from "./components/layout/LoginForm.vue"
import LoginPage from "./pages/login-page.vue"
import { http } from "./utils/http"
import Spinner from "./components/UI/Spinner.vue"

export default {
    name: "App",
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
        }),
        ...mapState("auth", ["authInitialized"]),
        ...mapState("auth", ["user"]),
    },
    components: { Spinner, Sidebar, LoginPage, Header },
    data() {
        return { loggingOut: false, navOpen: false }
    },
    methods: {
        openNav() {
            this.navOpen = true
        },
        closeNav() {
            this.navOpen = false
        },
        ...mapActions("auth", ["initAuth", "logout"]),
        async logout() {
            this.loggingOut = true
            await this.logout()
            window.location.href = "/login"
        },
    },
    async mounted() {
        await this.initAuth()
    },
}
</script>

<style scoped>
.closeButton {
    position: absolute;
    top: 120px;
    left: 84px;
}
.circle {
    position: absolute;
    top: 120px;

    left: 84px;
}
#app {
    /* font-family: "Nunito", Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
.main-bg {
    /* background: #e9fcfb; */
    background: #fff;
}
/* The sidebar menu */
.sidebar {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0;
    left: 0;
    background-color: #111; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

/* The sidebar links */
.sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
    color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    font-size: 22px;
}

/* The button used to open the sidebar */
.openbtn {
    font-size: 12px;
    cursor: pointer;

    color: black;
    /* padding: 10px 15px; */
    border: 1px solid rgb(125, 120, 120);
    border-radius: 50px;
    background: #fff;
}

.openbtn:hover {
    background-color: #444;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: margin-left 0.5s; /* If you want a transition effect */
    padding: 20px;
}
.main-view {
    padding-left: 300px;
    font-size: 0.8em;
}
.main-view-inner {
    padding-left: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidebar {
        padding-top: 15px;
    }
    .sidebar a {
        font-size: 18px;
    }
}
::-webkit-scrollbar {
    width: 6px;
    background-color: #ddd;
}
::-webkit-scrollbar-thumb {
    background-color: #8fb8ff;
}
</style>
