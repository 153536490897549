
import { Container } from "vue-smooth-dnd"
import { mapState } from "vuex"

export default {
    components: {
        Container,
    },
    computed: {
        ...mapState("project", ["activeProjectIssues"]),
        filteredIssues() {
            return this.activeProjectIssues.filter((issue) => issue.statusId._id == this.status._id)
        },
    },
    props: {
        status: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {}
    },
}
