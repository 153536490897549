<template>
    <div class="container">
        <div class="header">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" href="/">Ticket Software</a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <li :class="['nav-item', { active: '/home' == $route.path }]">
                            <a class="nav-link" href="/home"
                                >Home <span class="sr-only">(current)</span></a
                            >
                        </li>
                        <li
                            :class="['nav-item', { 'nav-item active': '/users' == $route.path }]"
                            v-if="user.isAdmin"
                        >
                            <router-link :to="{ name: 'users' }" class="nav-link"
                                >Users
                            </router-link>
                            <!-- <a class="nav-link" href="#"></a> -->
                        </li>

                        <!-- <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Your work
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li> -->
                    </ul>
                    <!-- <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form> -->
                </div>
            </nav>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex"
export default {
    data() {
        return {}
    },
    computed: {
        ...mapState("auth", ["authInitialized"]),
        ...mapState("auth", ["user"]),
    },
}
</script>
<style scoped>
/* .navbar-nav > .active > a { 
    background-color: rgb(175, 175, 239) ;
   
} */

li.nav-item.nav-item.active {
    border-bottom: 4px solid blue;
    border-radius: 2px;
}
</style>
