
import { mapState, mapActions } from "vuex"
import PageWrapper from "../../components/layout/PageWrapper.vue"
import IssueFilters from "../../components/other/IssueFilters.vue"
// import IssueCard from "../../components/card/IssueCard.vue"
import IssueRow from "../../components/card/IssueRow.vue"

export default {
    data() {
        return {
            search: "",
        }
    },
    computed: {
        ...mapState("auth", ["user", "isAdmin"]),
        ...mapState("project", ["activeProject", "activeProjectIssues", "issueStatuses", "fetchingIssues"]),
        activeStatus() {
            // @ts-ignore
            const issueStatuses = this.issueStatuses as any
            return issueStatuses && issueStatuses.find((item: any) => item.slug === this.$route.query.status)
        },
    },
    components: { PageWrapper, IssueRow, IssueFilters },
    methods: {
        ...mapActions("project", ["initIssues"]),
        filterIssues(id: string | null, slug: string) {
            let filteredIssues: any[] = []
            // @ts-ignore
            const activeProjectIssues = this.activeProjectIssues as any
            if (id) {
                filteredIssues = activeProjectIssues.filter((issue: any) => issue.statusId._id === id)
            } else if (slug) {
                filteredIssues = activeProjectIssues.filter((issue: any) => issue.statusId.slug === slug)
            } else {
                filteredIssues = activeProjectIssues
            }
            return filteredIssues.filter((item: any) => item.name.toLowerCase().includes(this.search.toLowerCase()))
        },
    },
    async mounted() {
        console.log("mounted running of new project")
        await this.initIssues({ projectSlug: this.$route.params.slug })
    },
    watch: {
        async $route(e, old) {
            if (old.params.slug != e.params.slug) {
                await this.initIssues({ projectSlug: this.$route.params.slug })
            }
        },
    },
}
