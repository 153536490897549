<template>
    <div :class="['custom-table-container']">
        <table :class="['custom-table my-3', { scrollable, bordered, borderedColumns, 'no-fixed-width': noFixedWidth }]">
            <thead>
                <slot name="head" />
            </thead>
            <tbody>
                <slot name="body" />
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapState } from "vuex"

export default {
    props: {
        noFixedWidth: {
            type: Boolean,
            default: false,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        borderedColumns: {
            type: Boolean,
            default: false,
        },
    },
    computed: {},
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.custom-table {
    width: 100%;
    background-color: #fff;
    thead tr {
        background-color: #f3f3f3;
    }
    th,
    td {
        padding: 10px 8px;
        height: 32px;
        border-bottom: none;
    }
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    // overflow: hidden;
    // border-collapse: separate;

    .borderedColumns {
    }
}
.custom-table.scrollable:not(.no-fixed-width) td {
    min-width: 170px;
}
.custom-table.bordered {
    border: 2px solid #eee;
}
.custom-table-container {
    overflow-x: scroll;

    tr:hover {
        background-color: #f6f6f6;
        // cursor: pointer;
    }
    th,
    td {
        border-spacing: 0; /* No space between cells */
        border: 1px solid #e3e3e3;

        // border-bottom: 1px solid #eee;
    }
    .borderedColumns {
        th,
        td {
            border: 1px solid #ddd;
        }
    }
}
</style>
