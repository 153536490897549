
import Lists from "@/components/Lists/Lists.vue"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/components/crud/utils.js"
import SelectDropdown from "@/components/other/SelectDropdown.vue"
import { http } from "@/utils/http"
import TaskStatusColumn from "./task-status-column.vue"

interface DataProps {
    selectedPriorityId: string
    search: string
    loading: boolean
    columns: any[]
    createFields: any[]
    initialized: boolean
    colors: any[]
    statuses: any[]
}
export default {
    components: { TaskStatusColumn, Lists, PageWrapper, CrudIndex, SelectDropdown },
    computed: {
        statusOptions() {
            // @ts-ignore
            return this.statuses.map((item) => ({ _id: item._id, title: item.name }))
        },
    },
    data(): DataProps {
        return {
            selectedPriorityId: "",
            search: "",
            loading: false,
            statuses: [],
            columns: [
                createColumn("Id", "_id", "string"),
                createColumn("Name", "name", "string"),
                createColumn("Colour", "color", "string"),
            ],
            createFields: [],
            initialized: false,
            colors: [
                // b series
                {
                    _id: "black",
                    title: "Black",
                },
                {
                    _id: "blue",
                    title: "Blue",
                },
                {
                    _id: "brown",
                    title: "Brown",
                },
                // c series
                {
                    _id: "coral",
                    title: "Coral",
                },
                {
                    _id: "cyan",
                    title: "Cyan",
                },
                // g series
                {
                    _id: "gold",
                    title: "Gold",
                },
                {
                    _id: "gray",
                    title: "Gray",
                },
                {
                    _id: "green",
                    title: "Green",
                },
                // i series
                {
                    _id: "indigo",
                    title: "Indigo",
                },
                // l series
                {
                    _id: "lime",
                    title: "Lime",
                },
                // m series
                {
                    _id: "magenta",
                    title: "Magenta",
                },
                {
                    _id: "maroon",
                    title: "Maroon",
                },
                // o series
                {
                    _id: "orange",
                    title: "Orange",
                },
                // p series
                {
                    _id: "pink",
                    title: "Pink",
                },
                {
                    _id: "purple",
                    title: "Purple",
                },
                // r series
                {
                    _id: "red",
                    title: "Red",
                },
            ],
        }
    },
    async mounted() {
        this.loadStatusStats()
        // const subjects = await this.fetchSubjects()
        this.createFields = [createField("Name*", "name", "text", true), createField("Colour*", "color", "select", true, "", this.colors)]
        this.loading = false
    },
    methods: {
        async loadStatusStats() {
            try {
                const res = await http.get("/status")
                this.statuses = res.data.result
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
        },
    },
}
