<template>
    <div class="login-container">
        <div class="row">
            <div class="col-md-7">
                <div class="my-4"></div>
                <div class="row gx-5">
                    <div class="col-md-3">
                        <div class="d-flex flex-col items-end">
                            <img class="mail-icon page-icon" src="@/assets/icons/mail_1.png" />
                            <div class="mail-bar"></div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="large-title">Welcome!</div>
                        <div class="large-title">Minutes Management...</div>
                        <p class="page-paragraph">Login now and start managing your tasks...</p>
                        <img class="sun-icon page-icon" src="@/assets/icons/sun_1.png" />
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <LoginForm />
            </div>
        </div>
    </div>
</template>

<script>
import LoginForm from "../components/layout/LoginForm.vue"
export default {
    components: { LoginForm },
}
</script>

<style lang="scss" scoped>
.login-container {
    padding: 120px 100px;
}
.large-title {
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 2em;
    color: #9095a1;
}
.page-paragraph {
    margin: 20px 0;
    color: #9095a1;
}
.mail-icon {
    width: 70px;
}
.sun-icon {
    width: 40px;
}
.mail-bar {
    background: #6d31ed;
    height: 8px;
    border-radius: 5px;
    margin: 10px 0;
    width: 140px;
}
</style>
