import { render, staticRenderFns } from "./AppBtn.vue?vue&type=template&id=36b94060&scoped=true&"
import script from "./AppBtn.vue?vue&type=script&lang=js&"
export * from "./AppBtn.vue?vue&type=script&lang=js&"
import style0 from "./AppBtn.vue?vue&type=style&index=0&id=36b94060&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b94060",
  null
  
)

export default component.exports