<template>
    <div>
        <input class="form-control" type="text" name="report-date" value="10/24/1984" />
    </div>
</template>

<script>
// requires libraries - daterangepicker.min.js, daterangepicker.css from index index.html
export default {
    emits: ["change"],
    mounted() {
        $(() => {
            $('input[name="report-date"]').daterangepicker(
                {
                    autoUpdateInput: false, // Don't automatically set the initial value
                    locale: {
                        format: "DD/MM/YYYY", // Set the display format
                    },
                },
                function (start, end, label) {
                    // When a range is selected, manually set the value in the input
                    $('input[name="report-date"]').val(start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY"))
                }
            )

            $('input[name="report-date"]').val("")
            $('input[name="report-date"]').on("cancel.daterangepicker", (ev, picker) => {
                //do something, like clearing an input

                this.$emit("change", {
                    startDateISO: "",
                    endDateISO: "",
                })
                $('input[name="report-date"]').val("")
            })

            $('input[name="report-date"]').on("apply.daterangepicker", (ev, picker) => {
                this.$emit("change", {
                    startDateISO: picker.startDate.format("YYYY-MM-DD"),
                    endDateISO: picker.endDate.format("YYYY-MM-DD"),
                })
            })
        })
    },
}
</script>

<style></style>
