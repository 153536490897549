import Dashboard from "../pages/dashboard.vue";
import DashboardProjects from "../pages/dashboard-projects.vue";
import Projects from "../pages/projects.vue";
import Profile from "../pages/profile.vue";
import Moms from "../pages/moms.vue";
import Companies from "../pages/companies.vue";
import Departments from "../pages/departments.vue";
import TaskStatus from "../pages/task-status/task-status.vue";
import TaskStatusStats from "../pages/task-status/task-status-stats.vue";
import TaskPriorities from "../pages/task-priorities.vue";
import CreateTask from "../pages/tasks/create.vue";
import CreateBulkTask from "../pages/tasks/create_bulk.vue";
import EditTask from "../pages/tasks/edit.vue";
import Users from "../pages/users.vue";
import UserRoles from "../pages/user-roles.vue";
import ProjectIndex from "../pages/projects/index.vue";
import TaskDeletedIndex from "../pages/tasks/deleted.vue";
import Skills from "@/pages/skills.vue";
import TaskReport from "@/pages/tasks/task-report.vue";

const routes = [
  { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },
  { path: "/companies", component: Companies },
  { path: "/projects", component: Projects },
  { path: "/profile", component: Profile },
  { path: "/moms", component: Moms },
  { path: "/task-report", component: TaskReport },
  { path: "/task-status", component: TaskStatus },
  { path: "/task-status-stats", component: TaskStatusStats },
  { path: "/task-priorities", component: TaskPriorities },
  { path: "/departments", component: Departments },
  { path: "/tasks/create", component: CreateTask },
  { path: "/tasks/create-bulk", component: CreateBulkTask },
  { path: "/tasks/deleted", component: TaskDeletedIndex },
  { path: "/tasks/:id", component: EditTask },
  { path: "/users", name: "users", component: Users },
  { path: "/skills", component: Skills },
  { path: "/user-roles", component: UserRoles },
  {
    path: "/companies/:slug/projects",
    component: DashboardProjects,
    name: "dashboard.project.slug",
  },
  { path: "/projects/:slug", component: ProjectIndex, name: "project.slug" },

  //fresh ecomnew routes path
];

export default routes;
