
import { localizeDate } from "@/utils/common"

export default {
    props: {
        task: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            showModal: false,
        }
    },
    methods: {
        localizeDate,
        deleteTask() {},
    },
}
