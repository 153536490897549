
import Lists from "../components/Lists/Lists.vue"
import ListProject from "../components/Lists/ListProject.vue"
import PageWrapper from "../components/layout/PageWrapper.vue"
import AppBtn from "../components/UI/AppBtn.vue"
import { mapState, mapActions } from "vuex"

export default {
    components: { ListProject, Lists, PageWrapper, AppBtn },
    data() {
        return {
            customers: {},
            products: {},
            orders: {},
            search: "",
        }
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapState("project", ["activeCompanyProjects", "loading", "activeProject", "activeProjectIssues", "issueStatuses"]),
        activeStatus() {
            return this.issueStatuses && this.issueStatuses.find((item) => item.slug === this.$route.query.status)
        },
        breadCrumbs() {
            return [{ title: "Welcome " + this.user.firstName, active: true }]
        },
        filteredActiveCompanyProjects() {
            return this.activeCompanyProjects
                ? this.activeCompanyProjects.filter((item) => item.name.toLowerCase().includes(this.search.toLowerCase()))
                : []
        },
    },
    methods: {
        ...mapActions("project", ["initIssues"]),
        filterIssues(id, slug) {
            if (id) {
                return this.activeProjectIssues.filter((issue) => issue.statusId._id === id)
            } else if (slug) {
                return this.activeProjectIssues.filter((issue) => issue.statusId.slug === slug)
            } else {
                return this.activeProjectIssues
            }
        },
        handleSearch(e) {
            this.search = e || ""
        },
    },
    async mounted() {
        await this.initIssues({ companySlug: this.$route.params.slug })
    },
    watch: {
        async $route(e, old) {
            if (old.params.slug != e.params.slug) {
                await this.initIssues({ companySlug: this.$route.params.slug })
            }
        },
    },
}
