<template>
    <button
        class="d-flex justify-content-center items-center"
        @click="$emit('click')"
        :class="['app-btn', { secondary, loading }]"
        style="min-width: 80px; gap: 10px"
    >
        <span v-if="loading" class="spinner-border spinner-border-sm"></span>
        <i v-else-if="icon" :class="`fas fa-${icon}`" style="font-size: 13px" />
        <slot />
    </button>
</template>

<script>
export default {
    props: {
        loading: { type: Boolean, default: false },
        secondary: { type: Boolean, default: false },
        icon: { type: String, default: "" },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.app-btn {
    border: none;
    padding: 8px 16px;
    min-height: 32px;
    border-radius: 6px;
    font-size: 1em;
    color: white;
    background-color: $primaryTheme;
    box-shadow: none;
    &:hover {
        opacity: 0.8;
    }
    &.loading {
        opacity: 0.5;
    }
}

.app-btn.secondary {
    background-color: #eee;
    color: #333;
    &:hover {
        background-color: #ddd;
    }
}
</style>
