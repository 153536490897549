import { http } from "@/utils/http"

// issue related data should be stored here...
import { FILTER_TYPES } from "@/utils/common"

const state = {
    token: "",
    user: null,
    companyInitialized: false,
    // active project related
    activeCompany: null,
    activeCompanyProjects: [],
    activeProject: null,
    activeProjectIssues: [],
    activeProjectMoms: [],
    fetchingIssues: false,

    // other non active project related
    allAssignees: [],
    allDepartments: [],
    allUsers: [],
    allProjects: [],
    issueStatuses: [],
    issuePriorities: [],
    issueLabels: [],
    loading: false,
    FILTER_TYPES,
    filterValues: {},

    // filters
}

const getters = {}

class FilterOption {
    constructor(_id, title) {
        this._id = _id
        this.title = title
    }
}
const mutations = {
    setData: (state, data) => {
        for (let key in data) {
            state[key] = data[key]
        }
    },

    initFilters: (state) => {
        // issueStatuses: result.issueStatuses,
        // issuePriorities: result.issuePriorities,
        // issueLabels: result.issueLabels,
        // companies: result.companies,
        // allAssignees: result.assignees,
        let newFilterValues = {}
        for (let key in FILTER_TYPES) {
            let filterType = FILTER_TYPES[key]
            let filterTypeValue = {
                type: filterType,
                options: [],
                selectedOptions: filterType.fieldType == "select" ? [] : "",
            }
            switch (filterType.type) {
                case FILTER_TYPES.PROJECT.type: {
                    filterTypeValue.options = state.allProjects.map((item) => new FilterOption(item._id, item.name))
                    if (state.activeProject) {
                        filterTypeValue.selectedOptions = [state.activeProject._id]
                    }
                    break
                }
                case FILTER_TYPES.STATUS.type: {
                    filterTypeValue.options = state.issueStatuses.map((item) => new FilterOption(item._id, item.name))
                    break
                }
                case FILTER_TYPES.PRIORITY.type: {
                    filterTypeValue.options = state.issuePriorities.map((item) => new FilterOption(item._id, item.name))
                    break
                }
                case FILTER_TYPES.DEPARTMENT.type: {
                    filterTypeValue.options = state.allDepartments.map((item) => new FilterOption(item._id, item.name))
                    break
                }
                case FILTER_TYPES.REPORTER.type: {
                    filterTypeValue.options = state.allUsers.map(
                        (item) => new FilterOption(item._id, `${item.firstName || ""} ${item.lastName || ""}`)
                    )
                    break
                }
                case FILTER_TYPES.ASSIGNEE.type: {
                    filterTypeValue.options = state.allUsers.map(
                        (item) => new FilterOption(item._id, `${item.firstName || ""} ${item.lastName || ""}`)
                    )
                    break
                }
                case FILTER_TYPES.MOM.type: {
                    filterTypeValue.options = state.activeProjectMoms.map((item) => new FilterOption(item._id, `${item.name}`))
                    break
                }
            }
            newFilterValues[filterType.type] = filterTypeValue
        }
        state["filterValues"] = newFilterValues
    },
}

const actions = {
    clearActiveCompany: async ({ commit }) => {
        commit("setData", {
            activeCompanyProjects: [],
            activeCompany: null,
        })
    },
    clearActiveProject: async ({ commit }) => {
        commit("setData", {
            activeProjectIssues: [],
            activeProject: null,
        })
    },
    async updateFilters({ state, commit, dispatch }, { type, value, fetchData = true }) {
        const filterValueCopy = {
            ...state.filterValues,
            [type.type]: {
                ...state.filterValues[type.type],
                selectedOptions: value,
            },
        }
        commit("setData", {
            filterValues: filterValueCopy,
        })
        if (fetchData) {
            // refetch issues
            await dispatch("fetchIssues")
        }
    },
    deleteIssue: async ({ state, commit, dispatch }, issueId) => {
        try {
            const res = await http.delete(`/issue/${issueId}`)
            commit("setData", {
                activeProjectIssues: state.activeProjectIssues.filter((item) => item._id != issueId),
            })
        } catch (err) {
            console.log("error in deleting project issue", err)
        }
    },
    fetchIssues: async ({ state, commit, dispatch }) => {
        try {
            commit("setData", { fetchingIssues: true })
            const res = await http.post("/issue/filter", {
                ...state.filterValues,
            })
            commit("setData", { activeProjectIssues: res.data.result })
        } catch (err) {
            console.log("error in loading active project issues")
            throw err
        }
        commit("setData", { fetchingIssues: false })
    },
    initIssues: async ({ commit, dispatch }, issueInitData = {}) => {
        let projectSlug
        let companySlug

        if (issueInitData) {
            projectSlug = issueInitData.projectSlug
            companySlug = issueInitData.companySlug
        }
        commit("setData", {
            loading: true,
        })
        console.log("project slug is", projectSlug)

        commit("setData", {
            activeProjectIssues: [],
            activeProject: null,
        })

        let response = await http.get(`/issue/init?projectSlug=${projectSlug || ""}&companySlug=${companySlug || ""}`)
        const result = response.data

        if (projectSlug || companySlug) {
            console.log("init issue project is running...")

            //  (response.data.success === true)
            if (projectSlug) {
                commit("setData", {
                    activeProjectMoms: result.moms,
                    activeProjectIssues: result.issues,
                    activeProject: result.activeProject,
                })

                let projectSelected = []
                if (!window.location.href.includes("search_mode")) {
                    projectSelected = [result.activeProject._id]
                }
                dispatch("updateFilters", {
                    type: FILTER_TYPES.PROJECT,
                    value: projectSelected,
                })
            }

            if (result && result.activeCompany) {
                commit("setData", {
                    activeCompany: result.activeCompany,
                })
            }

            if (result && result.projects) {
                commit("setData", {
                    activeCompanyProjects: result.projects,
                })
            }
        }

        if (!projectSlug && !companySlug) {
            commit("setData", {
                issueStatuses: result.issueStatuses,
                issuePriorities: result.issuePriorities,
                issueLabels: result.issueLabels,
                companies: result.companies,
                allAssignees: result.assignees,
                allDepartments: result.departments,
                allUsers: result.allUsers,
                allProjects: result.allProjects,
            })

            commit("setData", {
                companyInitialized: true,
            })
        }
        commit("setData", {
            loading: false,
        })

        // console.log("active project before calling ticket system", state.activeProject)
        commit("initFilters")

        return
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
