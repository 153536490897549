<template>
    <div>
        <div class="login-form-container">
            <div class="row">
                <div class="">
                    <div class="rounded pt-3 bg-white mt-1">
                        <div class="d-flex justify-content-center my-3">
                            <img src="@/assets/icons/hand_icon.png" style="width: 60px" />
                        </div>
                        <h2 class="text-center login-title">
                            {{ passwordResetMode ? "Reset your password" : processingForm ? "Welcome back" : "Login To Your account" }}
                        </h2>

                        <div class="text-center my-2 primary-color" v-if="processingForm">
                            <a href="javascript:void(0) primary-color" style="color: #0847a6">Login To Your Account </a>
                        </div>

                        <div class="card-body" v-if="!resetLinkSent">
                            <form @submit.prevent="handleSubmit">
                                <div v-if="!processingForm || passwordResetMode">
                                    <div class="mb-3 mt-3" v-if="!passwordResetMode">
                                        <CustomInput
                                            v-model="email"
                                            placeholder="example.email@gmail.com"
                                            type="text"
                                            :label="`Email Address*`"
                                        />
                                    </div>
                                    <div class="mb-3 mt-4" v-if="!showResetPassword">
                                        <CustomInput
                                            v-model="password"
                                            placeholder="Enter at least 8+ characters"
                                            type="password"
                                            :label="`Password*`"
                                        />
                                    </div>
                                    <div class="mb-3 mt-4" v-if="passwordResetMode">
                                        <CustomInput
                                            v-model="confirmPassword"
                                            placeholder="Re enter your password"
                                            type="password"
                                            :label="`Confirm Password*`"
                                        />
                                    </div>
                                </div>

                                <div class="my-4"></div>

                                <div class="mb-0">
                                    <div class="col-md-12" v-if="processingForm">
                                        <Spinner text="Just one second..." />
                                    </div>
                                    <div class="col-md-12" v-else>
                                        <button type="submit" class="login-btn" v-if="passwordResetMode">
                                            {{ processingForm ? "Please wait..." : "Reset Password" }}
                                        </button>
                                        <button type="submit" class="login-btn" v-else-if="showResetPassword">
                                            {{ processingForm ? "Please wait..." : "Email Password Reset Link" }}
                                        </button>
                                        <button type="submit" class="login-btn" v-else>
                                            {{ processingForm ? "Please wait..." : "Sign In" }}
                                        </button>
                                    </div>
                                    <div class="text-center mt-3" v-if="!processingForm && !passwordResetMode">
                                        <small>
                                            <span v-if="!showResetPassword">Can't Login?{{ " " }}</span>
                                            <a
                                                href="javascript:void(0)"
                                                class="text-decoration-underline"
                                                @click="showResetPassword = !showResetPassword"
                                                style="color: #0847a6"
                                                >{{ showResetPassword ? "Login Now" : "Click Here" }}
                                            </a>
                                        </small>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-else class="p-3 mt-5 m-3 bg-reset-sent text-white" style="border-radius: 10px">
                            Password reset link sent to your {{ email }}! Please check your email!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import { errMsg } from "../../utils/common"
import { http } from "../../utils/http"
import CustomInput from "../other/CustomInput.vue"
import Spinner from "../UI/Spinner.vue"

export default {
    data() {
        return {
            email: "",
            password: "",
            processingForm: false,
            showResetPassword: false,
            passwordResetMode: false,
            resetLinkSent: false,
            confirmPassword: "",
        }
    },
    components: { CustomInput, Spinner },
    mounted() {
        // this.passwordResetMode = true
        this.passwordResetMode = this.$route.path == "/reset-password"
    },
    methods: {
        // ...mapActions("auth", ["login"]),
        ...mapActions({
            login: "auth/login",
        }),
        async handleSubmit() {
            if (this.passwordResetMode) {
                await this.handleResetPassword()
            } else if (this.showResetPassword) {
                await this.handleResetLinkSubmit()
            } else {
                await this.handleLoginSubmit()
            }
        },
        async handleResetPassword() {
            if (this.processingForm) return

            if (!(this.password && this.confirmPassword && this.password === this.confirmPassword)) {
                return this.$toast.error("Please enter matching password and confirm password!")
            }
            this.processingForm = true
            try {
                await http.post(`/auth/reset-password?token=${this.$route.query.token}&email=${this.$route.query.email}`, {
                    password: this.password,
                })
                this.$toast.info("Password reset success!")
                window.location.href = "/login"
            } catch (err) {
                console.log("pw reset err", err)
                this.$toast.error(errMsg(err, "Could Not Reset Password!"))
            }
            this.processingForm = false
        },
        async handleResetLinkSubmit() {
            if (this.processingForm) return

            if (!this.email) {
                return this.$toast.error("Please enter email address of the account!")
            }
            this.processingForm = true
            try {
                await http.post(`/auth/reset-password-link`, {
                    email: this.email,
                })
                this.$toast.info("Password reset link sent!")
                this.resetLinkSent = true
            } catch (err) {
                console.log("password reset link send err", err)
                this.$toast.error(errMsg(err, "Could Not Send Reset link!"))
            }
            this.processingForm = false
        },
        async handleLoginSubmit() {
            if (this.processingForm) return

            if (!this.email || !this.password) {
                return this.$toast.error("Please Fill Details!")
            }
            this.processingForm = true
            try {
                await this.login({
                    email: this.email,
                    password: this.password,
                })
                this.$toast.info("Logged in Successfully!")
                window.location.href = "/dashboard"
            } catch (err) {
                console.log("Login err", err)
                this.$toast.error("Please enter valid credentials!")
            }
            this.processingForm = false
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.login-btn {
    width: 100%;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9em;
    background-color: $primaryTheme;
    box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.3);
}
.login-title {
    font-size: 30px;
    font-weight: bold;
}
.login-form-container {
    border: 3px solid $primaryTheme;
    border-radius: 5px;
    max-width: 450px;
    min-height: 35rem;
    padding: 20px;
}
.bg-reset-sent {
    background-color: $primaryTheme;
}
.primary-color {
    color: $primaryTheme;
}
</style>
