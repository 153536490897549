
import Lists from "../components/Lists/Lists.vue"
import ListProject from "../components/Lists/ListProject.vue"
import PageWrapper from "../components/layout/PageWrapper.vue"
import AppBtn from "../components/UI/AppBtn.vue"
import { mapState } from "vuex"
import HeaderSearch from "../components/UI/HeaderSearch.vue"

export default {
    components: { HeaderSearch, ListProject, Lists, PageWrapper, AppBtn },
    data() {
        return {
            customers: {},
            products: {},
            orders: {},
            search: "",
        }
    },
    mounted() {
        this.loadHomeData()
    },
    methods: {
        async loadHomeData() {
            try {
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
        },
        handleSearch(e) {
            this.search = e || ""
        },
    },
    computed: {
        ...mapState("project", ["companies", "loading"]),
        ...mapState("auth", ["user"]),
        filteredCompanies() {
            return this.companies.filter((item) => item.name.toLowerCase().includes(this.search.toLowerCase()))
        },
        breadCrumbs() {
            return [{ title: "Welcome " + this.user.firstName, active: true }]
        },
    },
}
