<template>
    <div class="mt-7">
        <div v-if="loading">Loading...</div>
        <div class="row gx-2" v-else>
            <div class="col-md-3" v-for="status in issueStatuses.slice(0, 4)" :key="status">
                <List :status="status" />
            </div>
        </div>
    </div>
</template>

<script>
import List from "@/components/Lists/List.vue"
import { mapState } from "vuex"

export default {
    components: {
        List,
    },
    data() {
        return {}
    },
    computed: {
        ...mapState("project", ["issueStatuses", "loading"]),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
