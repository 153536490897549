<template>
    <router-link
        :class="['sidebar-link-item', { active }]"
        :exact-active-class="disableActiveClass ? 'none' : 'sidebar-link-item-active'"
        :to="link"
    >
        <i :class="`icon ${iconType} fa-${icon}`"></i>
        <div>{{ title }}</div>
    </router-link>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "" },
        icon: { type: String, default: "" },
        iconType: { type: String, default: "fas" },
        link: { type: String, default: "#" },
        active: { type: Boolean, default: false },
        disableActiveClass: { type: Boolean, default: false },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.sidebar-link-item {
    padding: 10px 15px;
    text-align: left;
    align-items: center;
    display: flex;
    border-radius: 5px;
    font-size: 0.85em;
    margin-bottom: 5px;

    .icon {
        margin-right: 15px;
    }
    &:hover:not(.sidebar-link-item-active):not(.active):not(.router-link-active) {
        background-color: #fff;
        cursor: pointer;
    }
}
.sidebar-link-item.active,
.sidebar-link-item.router-link-active:not(.none) {
    background-color: $secondaryTheme;
    color: darken($primaryTheme, 15%);
}
</style>
