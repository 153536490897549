<template>
    <PageWrapper :showHeaderSearch="false" title="Projects" :breadCrumbs="[{ title: 'Tasks', active: true }]">
        <template #header-options> </template>
        <template>
            <div class="mt-3">
                <CrudIndex
                    v-if="!loading"
                    title="Project"
                    :viewColumns="columns"
                    fetchUrl="/projects"
                    createUrl="/projects"
                    updateRootUrl="/projects"
                    deleteRootUrl="/projects"
                    :createFields="createFields"
                    :editingFormatter="editingFormatter"
                />
            </div>
        </template>
    </PageWrapper>
</template>

<script>
import Lists from "@/components/Lists/Lists.vue"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import CrudIndex from "@/components/crud/index"
import { http } from "@/utils/http"
import { createField, createColumn } from "@/components/crud/utils"

export default {
    components: { Lists, PageWrapper, CrudIndex },
    data() {
        return {
            loading: false,
            columns: [
                createColumn("ID", "index", "string"),
                createColumn("Project Name", "name", "string"),
                createColumn("Created At", "createdAtFormatted2", "string"),
            ],
            createFields: [],
        }
    },
    async mounted() {
        const companies = await this.fetchCompanies()
        this.createFields = [
            createField("Name*", "name", "text", true),
            createField("Description", "description", "textarea", false),
            createField(
                "Company*",
                "companyId",
                "select",
                true,
                "",
                companies.map((item) => ({
                    _id: item._id,
                    title: item.name,
                }))
            ),
        ]
        this.loading = false
    },
    methods: {
        editingFormatter(item) {
            return {
                ...item,
            }
        },
        async fetchCompanies() {
            let result = []
            try {
                const res = await http.get("/companies")
                result = res.data.result
            } catch (err) {
                console.log("error in loading companies", err)
            }
            this.initialized = true
            return result
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
</style>
