<template>
    <div>
        <div class="attachment-item-container" v-if="fileName || !isUser">
            <div @click="removeFile" class="close-btn" v-if="fileName && !readonly && !isUser">
                <i class="fas fa-times"></i>
            </div>
            <div :class="['attachment-item', { active: isActive }]" @click="clickFile">
                <i :class="`fas fa-${!fileName ? 'plus' : 'file'}`"></i>
                <div style="font-size: 0.7em; padding: 5px">
                    <span v-if="fileName">{{ fileName.slice(0, 20) }}..</span>
                    <span v-else>Add File {{ id }}</span>
                </div>
            </div>
            <input style="display: none" type="file" @input="handleInput" :ref="'fileInput' + id" />
        </div>
    </div>
</template>

<script>
import { serverURL } from "@/utils/http"

function filterFileName(name) {
    return name.replaceAll("/uploads/", "").replaceAll("/uploads", "")
}

export default {
    model: {
        prop: "value",
        event: "input",
    },
    emits: ["input"],
    props: {
        name: { type: String, default: "" },
        id: { type: [String, Number], default: 1 },
        value: { type: [File, Object, String], default: () => null },
        readonly: { type: Boolean, default: false },
        isUser: { type: Boolean, default: false },
    },
    data() {
        return {
            fileName: "",
            file: "",
        }
    },
    mounted() {
        if (this.name) {
            this.fileName = filterFileName(this.name)
        }
    },
    methods: {
        handleInput(e) {
            if (e.target.files[0]) {
                this.file = e.target.files[0]
                console.log("this file", this.file)
                this.fileName = e.target.files[0].name
                this.$emit("input", this.file)
            }
        },
        removeFile() {
            this.file = null
            this.fileName = ""
            this.$emit("input", this.file)
            if (this.name) {
                this.$emit("remove", this.name)
            }
        },
        clickFile() {
            if (!this.isActive) {
                this.$refs["fileInput" + this.id].click()
            } else if (this.name) {
                window.open(serverURL + this.name)
            }
        },
    },
    computed: {
        isActive() {
            return !!this.fileName
        },
    },
}
</script>

<style lang="scss" scoped>
.attachment-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    word-break: break-all;
    background-color: #eee;
    width: 80px;
    height: 80px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.7);
    position: relative;
    &.active {
        background-color: #d7e6ff;
    }
    &:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
    }
}
.attachment-item-container {
    position: relative;
    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        background-color: white;
        width: 30px;
        height: 30px;
        display: grid;
        place-items: center;
        border-radius: 50%;
        top: -8px;
        right: -8px;
        border: 1px solid #555;
        z-index: 100;
        cursor: pointer;
        &:hover {
            background-color: rgb(255, 133, 133);
        }
    }
}
</style>
