<template>
    <div class="skill-edit-box d-flex justify-content-between" style="gap: 15px">
        <div class="d-flex" style="gap: 20px">
            <div>
                <div class="text-secondary skill-num">Skill #{{ index + 1 }}</div>
                <div class="my-1">
                    <div class="skill-control-options d-flex items-center" style="gap: 15px">
                        <label class="d-flex" style="gap: 5px">
                            <p>New Skill</p>
                            <input
                                :name="`skill-group-${index}`"
                                type="radio"
                                value="1"
                                :checked="skill.isNew == 1"
                                @input="$emit('isNewUpdate', $event.target.value)"
                            />
                        </label>
                        <label class="d-flex" style="gap: 5px">
                            <p>Existing Skill</p>
                            <input
                                :name="`skill-group-${index}`"
                                type="radio"
                                value="0"
                                :checked="skill.isNew == 0 || !skill.isNew"
                                @input="$emit('isNewUpdate', $event.target.value)"
                            />
                        </label>
                    </div>
                    <div style="min-width: 300px">
                        <div v-if="skill.isNew == 1">
                            <input
                                class="form-control"
                                placeholder="Enter skill name"
                                :value="skill.name"
                                @input="$emit('nameUpdate', $event.target.value)"
                                type="text"
                            />
                        </div>
                        <div v-else>
                            <SelectDropdown
                                :id="`skill-dropdown-${skill.skillId}`"
                                style="width: 300px"
                                :options="skillOptions"
                                :value="skill.skillId"
                                @change="$emit('update', $event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <div class="skill-num">Skill Level: {{ skill.percentage }}%</div>
                <input
                    style="width: 200px"
                    class="form-range"
                    :value="skill.percentage"
                    @input="$emit('percentageUpdate', $event.target.value)"
                    min="0"
                    max="100"
                    type="range"
                />
            </div>
        </div>
        <div class="">
            <AppBtn @click="$emit('remove')" style="background: crimson">
                <i class="fas fa-trash" />
                Remove Skill
            </AppBtn>
        </div>
    </div>
</template>

<script>
import SelectDropdown from "../other/SelectDropdown.vue"
import AppBtn from "../UI/AppBtn.vue"

export default {
    emits: ["update", "remove", "nameUpdate", "percentageUpdate", "isNewUpdate"],
    components: {
        SelectDropdown,
        AppBtn,
    },
    data() {
        return {}
    },
    methods: {},
    props: {
        index: {
            type: Number,
            default: 0,
        },
        skill: {
            type: Object,
            default: null,
        },
        skillOptions: {
            type: Array,
            default: [],
        },
    },
}
</script>

<style lang="scss" scoped>
.skill-edit-box {
    margin: 14px 0;
    background-color: #eee;
    padding: 15px;
    border-radius: 10px;
    align-items: items-center;
}
.skill-control-options {
    color: #555;
    margin-bottom: 10px;
}
</style>
