function hasIssuePermission(userId, assigneeUserIds, reporterId) {
    // console.log("has issue permission result", assigneeUserIds, userId, reporterId)

    console.log("check assignee user ids are", assigneeUserIds)

    let isUserAssignedForIssue = false
    if (userId == reporterId) {
        isUserAssignedForIssue = true
    } else {
        assigneeUserIds.map((item) => {
            if ((item._id && item._id == userId) || item == userId) {
                isUserAssignedForIssue = true
            }
        })
    }
    return isUserAssignedForIssue
}
const stripText = (str, length) => {
    if (str.length > length) {
        return str.slice(0, length) + "..."
    }
    return str
}

function errMsg(err, defaultMsg = "Some error occured") {
    // return suitable error message for catch responses
    let message = ""
    if (err) {
        message = err.response && err.response.data && err.response.data.message
    }
    return message || defaultMsg
}

const FILTER_TYPES = {
    PROJECT: { type: "project", fieldType: "select", title: "Project", multiple: true },
    STATUS: { type: "status", fieldType: "select", title: "Status", multiple: true },
    PRIORITY: { type: "priority", fieldType: "select", title: "Priority", multiple: true },
    DEPARTMENT: { type: "department", fieldType: "select", title: "Department", multiple: true },
    MOM: { type: "mom", fieldType: "select", title: "MOM", multiple: true },
    REPORTER: { type: "reporter", fieldType: "select", title: "Accountable Person", multiple: true },
    ASSIGNEE: { type: "assignee", fieldType: "select", title: "Responsible Person", multiple: true },
    START_DATE: { type: "startDate", fieldType: "date", title: "Start Date" },
    END_DATE: { type: "endDate", fieldType: "date", title: "Target Date" },
}

function localizeDate(date) {
    if (!date) return ""
    // format to indian date version dd/mm/yy
    const [yyyy, mm, dd] = new Date(date).toISOString().slice(0, 10).split("-")
    return `${dd}/${mm}/${yyyy.toString().slice(0, 4)}`
}

function mapUserFullName(userData) {
    if (userData) {
        return `${userData.firstName || ""} ${userData.lastName || ""}`
    } else {
        return ""
    }
}

export { stripText, hasIssuePermission, errMsg, FILTER_TYPES, localizeDate, mapUserFullName }
