import Vuex from "vuex"
import Vue from "vue"
import auth from "./auth"
import project from "./project"

Vue.use(Vuex)
// Create a new store instance.
const store = new Vuex.Store({
    modules: {
        auth,
        project,
    },
})

export default store
