<template>
    <PageWrapper :showHeaderSearch="false" title="Reports" :breadCrumbs="[{ title: 'Tasks', active: true }]">
        <template #header-options> </template>
        <template>
            <div class="mt-3">
                <div class="report-filters">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-2">Search Task Name</div>
                            <input type="search" v-model="taskName" class="form-control" />
                        </div>
                        <div class="col-md-4">
                            <div class="mb-2">Select User</div>
                            <SelectDropdown
                                :disabled="loading"
                                :multiple="true"
                                v-if="users.length"
                                id="user-selection"
                                @change="handleUserSelection"
                                :value="selectedUsers"
                                :options="users"
                            />
                        </div>
                        <div class="col-md-4">
                            <div class="mb-2">End Date/Deadline</div>
                            <DateRangePicker @change="handleDateChange" />
                        </div>
                        <div class="col-md-4 mt-3">
                            <div class="mb-2">Task Status</div>

                            <SelectDropdown
                                :disabled="loading"
                                :multiple="true"
                                v-if="statuses.length"
                                id="status-selection"
                                @change="selectedStatuses = $event"
                                :value="selectedStatuses"
                                :options="statuses"
                            />
                        </div>
                    </div>
                    <div class="mt-3 d-flex">
                        <AppBtn @click="fetchTaskReports" :loading="loading" style="width: 150px">Search</AppBtn>
                    </div>
                </div>

                <div v-if="!loading && taskReports.length == 0" class="my-5 text-center">No Results Found</div>
                <div v-if="!loading">
                    <div v-for="taskReport of taskReports" :key="taskReport.user._id">
                        <div>
                            <UserHoverDetail featuredMode :user="taskReport.user" />
                        </div>

                        <CustomTable scrollable>
                            <template #head>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Task</th>
                                    <th>Status</th>
                                    <th>End Date</th>
                                </tr>
                            </template>
                            <template #body>
                                <tr v-for="(task, i) of taskReport.issues" :key="task._id">
                                    <td style="width: 80px">
                                        {{ i + 1 }}
                                    </td>
                                    <td>
                                        <router-link target="_blank" :to="`/tasks/${task.taskId}`">{{ task.task }}</router-link>
                                    </td>
                                    <td style="width: 80px">
                                        <span :style="`color: ${task.statusColor}`">
                                            {{ task.status }}
                                        </span>
                                    </td>
                                    <td style="width: 80px">
                                        <span v-if="task.endDate">
                                            {{ localizeDate(task.endDate) }}
                                        </span>
                                        <span v-else> </span>
                                    </td>
                                </tr>
                            </template>
                        </CustomTable>
                    </div>
                </div>
                <div v-else style="padding-top: 100px">
                    <Spinner />
                </div>
            </div>
        </template>
    </PageWrapper>
</template>

<script>
import Lists from "@/components/Lists/Lists.vue"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import CrudIndex from "@/components/crud/index"
import { createField, createColumn } from "@/components/crud/utils"
import { http } from "@/utils/http"
import CustomTable from "@/components/crud/CustomTable.vue"
import UserHoverDetail from "@/components/card/UserHoverDetail.vue"
import Spinner from "@/components/UI/Spinner.vue"
import AppBtn from "@/components/UI/AppBtn.vue"
import { mapState } from "vuex"
import SelectDropdown from "@/components/other/SelectDropdown.vue"
import DateRangePicker from "@/components/UI/DateRangePicker.vue"
import { localizeDate } from "@/utils/common"

export default {
    components: { DateRangePicker, AppBtn, SelectDropdown, Spinner, UserHoverDetail, Lists, CustomTable, PageWrapper, CrudIndex },
    data() {
        return {
            customers: {},
            products: {},
            taskReports: [],
            selectedUsers: [],
            selectedStatuses: [],
            orders: {},
            loading: false,
            statuses: [],
            users: [],
            taskName: "",
            startDateISO: "",
            endDateISO: "",
        }
    },
    computed: {},
    async mounted() {
        this.fetchTaskStatuses()
        this.fetchUsers()
        this.fetchTaskReports()
    },
    methods: {
        localizeDate,
        editingFormatter(item) {
            return {
                ...item,
            }
        },
        handleDateChange(dateData) {
            console.log("handle date change", dateData)
            if (dateData && dateData.startDateISO && dateData.endDateISO) {
                this.startDateISO = dateData.startDateISO
                this.endDateISO = dateData.endDateISO
            }
        },
        async fetchTaskStatuses() {
            try {
                const res = await http.get("/status")
                this.statuses = res.data.result.map((item) => ({ _id: item._id, title: item.name }))
            } catch (err) {
                console.log("error in fetching users", err)
            }
        },
        async fetchUsers() {
            try {
                const res = await http.get("/users")
                this.users = res.data.result.map((item) => ({ _id: item._id, title: (item.firstName || "") + " " + (item.lastName || "") }))
            } catch (err) {
                console.log("error in fetching users", err)
            }
        },
        async fetchTaskReports() {
            if (this.loading) return
            try {
                this.loading = true
                const res = await http.get(
                    `/issue/reports?search=${this.taskName}&user_ids=${this.selectedUsers.join(",")}&start_date=${
                        this.startDateISO
                    }&end_date=${this.endDateISO}&status_ids=${this.selectedStatuses}`
                )
                this.taskReports = res.data.result
            } catch (err) {
                console.log("error in loading task reports", err)
            }
            this.loading = false
        },
        handleUserSelection(users) {
            this.selectedUsers = users
            console.log("users", users)
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
.report-filters {
    background: #eee;
    padding: 15px;
    margin-bottom: 15px;
}
</style>
