<template>
    <div class="accordion-wrapper">
        <div class="accordion-header" @click="show = !show">
            <div class="highlight-text">{{ title }}</div>
            <div class="highlight-sub-text">{{ subtitle }}</div>
        </div>
        <div :class="['accordion-content', { show }]">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "" },
        subtitle: { type: String, default: "" },
        defaultShow: { type: Boolean, default: false },
    },
    data() {
        return { show: false }
    },
    mounted() {
        this.show = this.defaultShow
    },
}
</script>

<style lang="scss" scoped>
.accordion-wrapper {
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.accordion-header {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
        background: #ddd;
    }
}
.accordion-content {
    max-height: 0;
    font-size: 0.8em;
    overflow: hidden;
    transition: 0.25s;
    border-top: none;

    &.show {
        padding: 15px;
        max-height: unset;
    }
}
.highlight-text {
    font-size: 1em;
    margin-right: 5px;
    font-weight: bold;
}
.highlight-sub-text {
    font-size: 0.8em;
    color: #999;
}
</style>
