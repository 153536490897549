<template>
    <PageWrapper title="Create Task" :show-header-search="false" :breadCrumbs="[{ title: 'Tasks', active: true }]">
        <template #header-options> </template>
        <template>
            <div class="">
                <CrudIndex
                    v-if="!loading"
                    @created="$router.push('/tasks/' + $event._id)"
                    title="MOM"
                    :columnCount="4"
                    :onlyCreate="true"
                    :viewColumns="columns"
                    :showTableData="false"
                    fetchUrl="/issue"
                    createUrl="/issue"
                    updateRootUrl="/issue"
                    deleteRootUrl="/issue"
                    :createFields="createFields"
                    :editingFormatter="editingFormatter"
                    @formDataUpdated="handleFormDataUpdate"
                />
                <div v-else>Please wait...</div>
            </div>
        </template>
    </PageWrapper>
</template>

<script>
import Lists from "@/components/Lists/Lists.vue"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import CrudIndex from "@/components/crud/index"
import { createField, createColumn } from "@/components/crud/utils"
import { mapState, mapActions } from "vuex"
import { http } from "@/utils/http"
import { mapUserFullName } from "@/utils/common"

export default {
    components: { Lists, PageWrapper, CrudIndex },
    computed: {
        ...mapState("auth", ["user", "isAdmin"]),
        ...mapState("project", ["projects", "issueStatuses", "activeCompany", "activeProject"]),
    },
    data() {
        return {
            customers: {},
            products: {},
            currentCompanyId: "",
            orders: {},
            loading: false,
            columns: [
                createColumn("Id", "index", "string"),
                createColumn("Name", "name", "string"),
                createColumn("Created At", "createdAtFormatted2", "string"),
            ],
            createFields: [],
            issueCreateOptions: [],
            showPendingUsersSelection: false,
            minEndDate: "",
            maxEndDate: "",
            minInterimDate: "",
            maxInterimDate: "",
            endDateFilled: false,
            projectsToSelect: [],
            momsToSelect: [],
            defaultSelectedCompany: "",
            defaultSelectedProject: "",
            defaultSelectedMom: "",
            defaultSelectedMomTypeId: 1,
            showMomSelectInput: false,
            showMomTextInput: false,
            momsToSelectType: [
                {
                    _id: 1,
                    title: "New Mom",
                },
                {
                    _id: 2,
                    title: "Existing Mom",
                },
            ],
        }
    },
    async mounted() {
        this.loading = true
        this.issueCreateOptions = await this.fetchIssueCreationOptions()
        if (this.activeProject) {
            this.defaultSelectedProject = this.activeProject && this.activeProject._id
        }
        if (this.activeCompany) {
            this.defaultSelectedCompany = this.activeCompany && this.activeCompany._id

            this.projectsToSelect = this.issueCreateOptions.projects.filter((item) => item.companyId == this.activeCompany._id)
        }
        this.recreateFields()
        this.loading = false
    },
    methods: {
        ...mapActions("auth", ["checkRole"]),
        mapUserFullName,
        recreateFields() {
            let creatorField
            if (this.isAdmin) {
                creatorField = createField(
                    "Accountable Person*",
                    "reporterId",
                    "select",
                    true,
                    "",
                    this.issueCreateOptions.allUsers.map((item) => ({
                        _id: item._id,
                        title: mapUserFullName(item),
                    }))
                )
            } else {
                creatorField = createField("Creator*", "reporterId", "hidden", true, this.user._id)
            }
            let createFields = [
                createField(
                    "Company*",
                    "companyId",
                    "select",
                    true,
                    this.defaultSelectedCompany,
                    this.issueCreateOptions.companies.map((item) => ({
                        _id: item._id,
                        title: item.name,
                    }))
                ),
                createField(
                    "Project*",
                    "projectId",
                    "select",
                    true,
                    this.defaultSelectedProject,
                    this.projectsToSelect.map((item) => ({
                        _id: item._id,
                        title: item.name,
                    }))
                ),
                createField("New Mom / Existing Mom*", "momType", "select", true, this.defaultSelectedMomTypeId, this.momsToSelectType),
            ]

            if (this.showMomSelectInput) {
                createFields.push(
                    createField(
                        "Mom*",
                        "momId",
                        "select",
                        true,
                        this.defaultSelectedMom,
                        this.momsToSelect.map((item) => ({
                            _id: item._id,
                            title: item.name,
                        }))
                    )
                )
            }
            if (this.showMomTextInput) {
                createFields.push(createField("Mom Name*", "momName", "text", true))
            }

            createFields = [
                ...createFields,
                createField(
                    "Status*",
                    "statusId",
                    "select",
                    true,
                    "",
                    this.issueCreateOptions.issueStatuses.map((item) => ({
                        _id: item._id,
                        title: item.name,
                    }))
                ),
                createField(
                    "Task Pending Due to Users*",
                    "pendingDueToUserIds",
                    this.showPendingUsersSelection ? "multiselect" : "hidden",
                    false,
                    "",
                    this.issueCreateOptions.allUsers.map((item) => ({
                        _id: item._id,
                        title: mapUserFullName(item),
                    }))
                ),
                createField("Short Summary*", "name", "text", true),

                creatorField,
                createField(
                    "Responsible Person*",
                    "assigneeUserIds",
                    "multiselect",
                    true,
                    "",
                    this.issueCreateOptions.allUsers.map((item) => ({
                        _id: item._id,
                        title: mapUserFullName(item),
                    }))
                ),
                createField(
                    "Report To*",
                    "reportToIds",
                    "multiselect",
                    true,
                    "",
                    this.issueCreateOptions.allUsers.map((item) => ({
                        _id: item._id,
                        title: mapUserFullName(item),
                    }))
                ),
                createField(
                    "Consult To*",
                    "consultToIds",
                    "multiselect",
                    true,
                    "",
                    this.issueCreateOptions.allUsers.map((item) => ({
                        _id: item._id,
                        title: mapUserFullName(item),
                    }))
                ),
                createField(
                    "Inform To*",
                    "informToIds",
                    "multiselect",
                    true,
                    "",
                    this.issueCreateOptions.allUsers.map((item) => ({
                        _id: item._id,
                        title: mapUserFullName(item),
                    }))
                ),
                createField("Start Date*", "startDate", "date", true, new Date().toISOString().slice(0, 10)),
                createField(
                    "Target Date (must be after start date)*",
                    "endDate",
                    `date|${this.minEndDate}|${this.maxEndDate}|disabled:false`,
                    true,
                    ""
                ),
                // createField(
                //     "Interim Date (between start and target date)",
                //     "interimDate",
                //     `date|${this.minInterimDate}|${this.maxInterimDate}|disabled:${this.endDateFilled ? "false" : "true"}`,
                //     false,
                //     null
                // ),
                createField(
                    "Departments*",
                    "departmentIds",
                    "multiselect",
                    true,
                    "",
                    this.issueCreateOptions.departments.map((item) => ({
                        _id: item._id,
                        title: item.name,
                    }))
                ),
                createField(
                    "Priority*",
                    "priorityId",
                    "select",
                    true,
                    "",
                    this.issueCreateOptions.issuePriorities.map((item) => ({
                        _id: item._id,
                        title: item.name,
                    }))
                ),
                createField("Attachment 1", "attachment1", "file", false),
                createField("Attachment 2", "attachment2", "file", false),
                createField("Attachment 3", "attachment3", "file", false),
                createField("Description", "description", "textarea", false),
            ]

            // update create fields
            this.createFields = createFields.reverse().sort((a, b) => (a.type == "hidden" ? 1 : -1))
            console.log("create fields", this.createFields)
        },
        async handleFormDataUpdate(val) {
            console.log("handle form data update", val)

            if (val) {
                if (val.momType) {
                    this.showMomSelectInput = false
                    this.showMomTextInput = false
                    if (val.momType == 1) {
                        this.showMomTextInput = true
                    } else {
                        // fetch mom select inputs
                        this.showMomSelectInput = true
                    }
                    this.recreateFields()
                }

                if (val.projectId) {
                    try {
                        const res = await http.get(`/moms?projectId=${val.projectId}`)
                        this.momsToSelect = res.data.result || []
                    } catch (err) {
                        console.log("error in loading moms", err)
                        this.$toast.error("Error in loading moms")
                    }
                    this.recreateFields()
                }

                if (val.statusId) {
                    const pendingStatus = this.issueStatuses.find((item) => item.slug === "pending")
                    const statusId = val.statusId
                    if (pendingStatus && pendingStatus._id === statusId) {
                        this.showPendingUsersSelection = true
                    } else {
                        this.showPendingUsersSelection = false
                    }

                    this.recreateFields()
                    // minInterimDate
                    // maxInterimDate
                }

                if (val.startDate || val.endDate) {
                    if (val.startDate) {
                        this.minEndDate = val.startDate
                        this.minInterimDate = val.startDate
                    }
                    if (val.endDate) {
                        this.maxInterimDate = val.endDate
                        this.endDateFilled = true
                    }
                    this.recreateFields()
                }

                if (!this.currentCompanyId || (val.companyId && val.companyId != this.currentCompanyId)) {
                    this.currentCompanyId = val.companyId
                    this.projectsToSelect = this.issueCreateOptions.projects.filter((item) => item.companyId == val.companyId)
                    this.recreateFields()
                }

                // minEndDate
                // maxEndDate
                // minInterimDate
                // maxInterimDate
            }
        },
        editingFormatter(item) {
            console.log("editing formatter running", item)
            return {
                ...item,
            }
        },
        async fetchIssueCreationOptions() {
            let result = {}
            try {
                const res = await http.get("/issue/init?all_projects=true")
                result = res.data
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
            return result
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
</style>
