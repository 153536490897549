<template>
    <div class="custom-input-wrapper">
        <div :class="[{ small }]">
            <div class="mb-1" v-if="label">
                <label class="custom-form-label" :for="inputId">{{ label }}</label>
            </div>
            <div class="d-flex items-center" style="gap: 8px">
                <i v-if="icon" :class="`fas fa-${icon}`" style="font-size: 13px; color: #777" />
                <input
                    v-if="type == 'file'"
                    :id="inputId"
                    :type="type"
                    accept="image/*,application/pdf"
                    class="custom-form-input"
                    @input="emitInput($event.target.files[0])"
                />
                <textarea
                    v-else-if="type == 'textarea'"
                    rows="3"
                    :id="inputId"
                    :placeholder="placeholder"
                    class="custom-form-input"
                    :value="value"
                    @input="emitInput($event.target.value)"
                />
                <input
                    v-else
                    step="any"
                    :type="type"
                    :placeholder="placeholder"
                    :value="value"
                    class="custom-form-input"
                    @input="emitInput($event.target.value)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"

export default {
    props: {
        icon: { type: String, default: "" },
        small: { type: Boolean, default: false },
        label: String,
        value: { type: [String, Number] },
        inputId: String,
        type: { type: String, default: "text" },
        placeholder: { type: String, default: "" },
    },
    model: {
        prop: "value",
        event: "input",
    },
    computed: {
        ...mapState("misc", ["theme", "THEMES"]),
    },
    methods: {
        emitInput(data) {
            this.$emit("input", data)
            this.$emit("change", data)
        },
    },
}
</script>

<style lang="scss" scoped>
.custom-form-input:not([type="radio"], [type="checkbox"]) {
    width: 100%;
}
.custom-input-wrapper {
    background-color: #f3f4f6;
    padding: 5px 12px;
    border-radius: 5px;
}
.small {
    .custom-form-input {
        font-size: 1.05em;
    }
}
.custom-form-input {
    border: 1px solid #999;
    resize: none;
    color: #555;
    border-radius: 5px;
    outline: none;
    background: transparent;
    border: none;
}
.custom-form-input:hover,
.custom-form-input:focus {
}
.custom-form-label {
    color: #222;
    font-size: 0.9em;
    font-weight: 500;
}
</style>
