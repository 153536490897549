<template>
  <PageWrapper
    title="Create Bulk Task"
    :show-header-search="false"
    :breadCrumbs="[{ title: 'Bulk Task Creation', active: true }]"
  >
    <template #header-options> </template>
    <template>
      <div class="mt-1 row">
        <div class="col-md-3">
          <label class="custom-label">Select Company</label>
          <SelectDropdown
            v-if="companyInitialized"
            id="company"
            :additionalDelayTrigger="true"
            :options="companies.map((item) => ({ _id: item._id, title: item.name }))"
            field="company"
            :value="activeCompany && activeCompany._id"
            @change="handleCompanyChange"
          />
        </div>
        <div v-if="!!activeCompany" class="col-md-3">
          <label class="custom-label">Select Project</label>
          <SelectDropdown
            id="project"
            :additionalDelayTrigger="true"
            :options="
              activeCompanyProjects.map((item) => ({
                _id: item._id,
                title: item.name,
              }))
            "
            field="project"
            :value="activeProject && activeProject._id"
            @change="handleProjectChange"
          />
        </div>
        <div class="col-md-3" v-if="!!activeProject">
          <label class="custom-label">Create MOM</label>
          <div class="row">
            <div class="col-md-6 pr-1">
              <input
                v-model="existingMom"
                :value="true"
                class="form-check-input me-1"
                type="radio"
                id="existing_mom"
              />
              <label for="existing_mom" class="form-check-label">Existing MOM</label>
            </div>
            <div class="col-md-6">
              <input
                v-model="existingMom"
                :value="false"
                class="form-check-input me-1"
                type="radio"
                id="new_mom"
              />
              <label for="new_mom" class="form-check-label">New MOM</label>
            </div>
          </div>
          <div v-if="existingMom" class="my-2">
            <SelectDropdown
              id="mom"
              :additionalDelayTrigger="true"
              :options="
                moms.map((item) => ({
                  _id: item._id,
                  title: item.name,
                }))
              "
              field="mom"
              :value="selectedMom && selectedMom._id"
              @change="handleMomChange"
            />
          </div>
          <div v-else class="my-2">
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="MOM Name"
              v-model="momName"
            />
          </div>
        </div>
        <div class="col-md-2">
          <label class="custom-label">Date of Meeting</label>
          <input v-model="startDate" type="date" class="form-control form-control-sm" />
        </div>
      </div>

      <!-- table creator for mom task -->
      <div class="issue-create-table-container">
        <div class="text-end">
          <AppBtn :loading="processingForm || loading" @click="saveChanges"> Save Changes </AppBtn>
        </div>
        <table
          class="table table-bordered table-light table-condensed my-3 issue-create-table"
          v-if="issueCreateOptions"
        >
          <tr class="tbleheader">
            <th style="padding-right: 15px" class="px-2 py-2">SR</th>
            <th class="">Del</th>
            <th class="px-2">Start Date</th>
            <th class="px-2">Title of task</th>
            <th v-if="existingMom" class="px-2">Task</th>

            <th class="px-2">Accountable Person</th>
            <th class="px-2">Responsible Person</th>
            <th class="px-2">Consult To</th>
            <th class="px-2">Inform To</th>
            <th class="px-2">Status</th>
            <!-- <th class="px-2">Report to</th> -->
            <th class="px-2">Departments</th>
            <th class="px-2">Priority</th>
            <!-- <th class="px-2">Interim Date</th> -->
            <th class="px-2">End Date</th>
          </tr>
          <tr class="tblco" v-for="(issueEdit, i) of editingIssues" :key="issueEdit.id">
            <td class="px-2">{{ i + 1 }}</td>
            <td class="px-2">
              <i class="fas fa-trash" @click="removeMomTask(issueEdit)" style="color: red"></i>
            </td>
            <td>
              <input
                v-model="editingIssues[i].startDate"
                type="date"
                :min="minStartDate"
                class="form-control form-control-sm interidt"
              />
            </td>
            <td>
              <input
                style="min-width: 200px"
                placeholder="Task Title"
                class="interidt"
                type="text"
                v-model="editingIssues[i].name"
              />
            </td>
            <th v-if="existingMom">
              <SelectDropdown
                :id="`mom-${i}`"
                :additionalDelayTrigger="true"
                :options="
                  moms.map((item) => ({
                    _id: item._id,
                    title: item.name,
                  }))
                "
                :field="`mom-${i}`"
                :value="editingIssues[i].momId"
                @change="editingIssues[i].momId = $event"
              />
            </th>
            <td>
              <SelectDropdown
                :id="`issue-reporter`"
                :options="
                  issueCreateOptions.allUsers.map((item) => ({
                    _id: item._id,
                    title: mapUserFullName(item),
                  }))
                "
                :multiple="false"
                :field="'issue-reporter'"
                :value="editingIssues[i].reporterId"
                @change="editingIssues[i].reporterId = $event"
              />
            </td>
            <td>
              <SelectDropdown
                :id="`issue-assignees`"
                :options="
                  issueCreateOptions.assignees.map((item) => ({
                    _id: item._id,
                    title: mapUserFullName(item),
                  }))
                "
                :multiple="true"
                :field="'issue-assignees'"
                :value="editingIssues[i].assigneeUserIds"
                @change="editingIssues[i].assigneeUserIds = $event"
              />
            </td>
            <td>
              <SelectDropdown
                :id="`consult-to`"
                :options="
                  issueCreateOptions.allUsers.map((item) => ({
                    _id: item._id,
                    title:  mapUserFullName(item),
                  }))
                "
                :multiple="true"
                :field="'consult-to'"
                :value="editingIssues[i].consultToIds"
                @change="editingIssues[i].consultToIds = $event"
              />
            </td>
            <td>
              <SelectDropdown
                :id="`inform-to`"
                :options="
                  issueCreateOptions.allUsers.map((item) => ({
                    _id: item._id,
                    title: mapUserFullName(item),
                  }))
                "
                :multiple="true"
                :field="'inform-to'"
                :value="editingIssues[i].informToIds"
                @change="editingIssues[i].informToIds = $event"
              />
            </td>
            <td>
              <SelectDropdown
                :id="`issue-status`"
                :options="
                  issueCreateOptions.issueStatuses.map((item) => ({
                    _id: item._id,
                    title: item.name,
                  }))
                "
                :multiple="false"
                :field="'issue-status'"
                :value="editingIssues[i].statusId"
                @change="editingIssues[i].statusId = $event"
              />
            </td>
            <!-- <td>
                            <SelectDropdown
                                :id="`report-to-ids`"
                                :options="
                                    issueCreateOptions.allUsers.map((item) => ({
                                        _id: item._id,
                                        title: mapUserFullName(item),
                                    }))
                                "
                                :multiple="true"
                                :field="'report-to-ids'"
                                :value="editingIssues[i].reportToIds"
                                @change="editingIssues[i].reportToIds = $event"
                            />
                        </td> -->
            <td>
              <SelectDropdown
                :id="`issue-departments`"
                :options="
                  issueCreateOptions.departments.map((item) => ({
                    _id: item._id,
                    title: item.name,
                  }))
                "
                :multiple="true"
                :field="'issue-departments'"
                :value="editingIssues[i].departmentIds"
                @change="editingIssues[i].departmentIds = $event"
              />
            </td>
            <td>
              <SelectDropdown
                :id="`issue-priority`"
                :options="
                  issueCreateOptions.issuePriorities.map((item) => ({
                    _id: item._id,
                    title: item.name,
                  }))
                "
                :multiple="false"
                :field="'issue-priority'"
                :value="editingIssues[i].priorityId"
                @change="editingIssues[i].priorityId = $event"
              />
            </td>
            <!-- <td>
                            <input
                                v-model="editingIssues[i].interimDate"
                                type="date"
                                :min="minInterimDate"
                                class="form-control form-control-sm interidt"
                            />
                        </td> -->
            <td>
              <input
                v-model="editingIssues[i].endDate"
                type="date"
                :min="minEndDate"
                class="form-control form-control-sm interidt"
              />
            </td>
          </tr>
        </table>
        <div class="my-2" v-else-if="loading">Loading...</div>
        <div class="my-2" v-else>No Tasks Added</div>
      </div>
      <AppBtn style="width: 100%" class="my-3" @click="addNewTask"
        >Add Task <i class="fas fa-plus"
      /></AppBtn>
    </template>
  </PageWrapper>
</template>

<script>
import Lists from "@/components/Lists/Lists.vue";
import PageWrapper from "@/components/layout/PageWrapper.vue";
import CrudIndex from "@/components/crud/index";
import { createField, createColumn } from "@/components/crud/utils";
import { mapState, mapActions } from "vuex";
import SelectDropdown from "@/components/other/SelectDropdown.vue";
import { http } from "@/utils/http";
import { errMsg, mapUserFullName } from "@/utils/common";
import AppBtn from "@/components/UI/AppBtn";

export default {
  components: { SelectDropdown, Lists, AppBtn, PageWrapper, CrudIndex },
  computed: {
    ...mapState("auth", ["user", "isAdmin"]),
    ...mapState("project", ["companies", "companyInitialized"]),
  },
  data() {
    return {
      loading: false,
      issueCreateOptions: null,
      // dates
      defaultStartDate: new Date().toISOString().slice(0, 10),
      minStartDate: new Date().toISOString().slice(0, 10),
      minEndDate: new Date().toISOString().slice(0, 10),
      maxEndDate: new Date().toISOString().slice(0, 10),
      minInterimDate: new Date().toISOString().slice(0, 10),
      maxInterimDate: new Date().toISOString().slice(0, 10),
      endDateFilled: false,
      projectsToSelect: [],
      defaultSelectedCompany: "",
      defaultSelectedProject: "",
      activeCompanyProjects: [],
      activeCompany: null,
      activeProject: null,
      moms: [],
      selectedMom: null,
      existingMom: false,
      momName: "",
      editingIssues: [],
      processingForm: false,
    };
  },
  async mounted() {
    await this.fetchIssueCreationOptions();
  },
  methods: {
    ...mapActions("auth", ["checkRole"]),
    mapUserFullName,
    checkMomTaskSelected() {
      if (!this.existingMom) {
        if (!this.momName) {
          this.$toast.error("MOM Name is required!");
          return false;
        }
      } else if (!this.selectedMom) {
        this.$toast.error("Please select a MOM task!");
        return false;
      }
      return true;
    },
    async saveChanges() {
      if (!this.activeProject) {
        return this.$toast.error("Please select company and project!");
      }

      let filteredTasks = this.editingIssues.filter((item) => {
        // filter out rows which have not been filled
        if (this.checkAllFieldsEmpty(item)) {
          return false;
        }
        return true;
      });

      if (!filteredTasks.length) {
        return this.$toast.error("Please enter at least one task!");
      }
      let createdMom = null;
      if (!this.checkMomTaskSelected() || this.processingForm) return;

      const emptyFields = this.editingIssues.some((item) => {
        const momIdCondition = item.momId || !this.existingMom;
        const containsSomeEmptyFields =
          !item.name ||
          !item.assigneeUserIds.length ||
          !item.priorityId ||
          !momIdCondition ||
          // other fields
          // !item.reportToIds.length ||
          !item.statusId ||
          // !item.endDate ||
          // !item.interimDate ||
          // normal fields
          !item.departmentIds.length;
        const partiallyFilledFields = !this.checkAllFieldsEmpty(item) && containsSomeEmptyFields;
        return partiallyFilledFields;
      });
      if (emptyFields) {
        console.log("this.editingIssues is empty somewhere", this.editingIssues);
        return this.$toast.error("Please fillup all the fields for the tasks!");
      }

      if (!this.existingMom) {
        if (!this.momName) {
          return this.$toast.error("MOM Name is required!");
        }
        // create a mom
        this.processingForm = true;
        const momRes = await http.post("/moms", {
          name: this.momName,
          projectId: this.activeProject._id,
        });
        createdMom = momRes.data.result;
      }
      this.processingForm = true;

      // next continue creation of tasks
      try {
        const res = await http.post("/issue/multi", {
          tasks: filteredTasks.map((item) => {
            const mapResult = {
              ...item,
              projectId: this.activeProject._id,
              startDate: item.startDate || this.defaultStartDate,
            };
            if (!this.existingMom) {
              mapResult.momId = createdMom._id;
            }
            return mapResult;
          }),
        });
        this.$toast.success("Tasks created successfully!");
        this.$router.push(`/projects/${this.activeProject.slug}?search_mode=true`);
      } catch (err) {
        console.log("errro in creating task", err);
        this.$toast.error(errMsg(err));
      }
      this.processingForm = false;
    },
    addNewTask() {
      if (!this.activeCompany || !this.activeProject) {
        return this.$toast.error("Please select company and project first!");
      }
      if (this.existingMom && !this.selectedMom) {
        return this.$toast.error("Please select existing mom!");
      }
      this.editingIssues = this.editingIssues.concat({
        id: this.editingIssues.length + 1,
        name: "",
        assigneeUserIds: [],
        consultToIds: [],
        informToIds: [],
        priorityId: "",
        momId: this.selectedMom && this.selectedMom._id,
        // other fields
        reporterId: "",
        reportToIds: [],
        statusId: "",
        startDate: this.defaultStartDate,
        endDate: "",
        interimDate: "",
        // normal fields
        departmentIds: [],
      });
    },
    removeMomTask(removeItem) {
      this.editingIssues = this.editingIssues.filter((item) => item.id != removeItem.id);
    },
    async handleCompanyChange(companyId) {
      this.activeCompany = this.companies.find(
        (item) => item._id.toString() == companyId.toString()
      );
      this.activeProject = null;
      this.activeCompanyProjects = [];
      // fetch active company projects
      const projectData = await http.get(`/projects?companyId=${companyId}`);
      this.activeCompanyProjects = projectData.data.result;
    },
    async handleProjectChange(projectId) {
      // console.log("activeCompanyProjects", this.activeCompanyProjects, projectId)
      this.activeProject = this.activeCompanyProjects.find(
        (item) => item._id.toString() == projectId.toString()
      );
      // fetch active company projects
      const momData = await http.get(`/moms?projectId=${projectId}`);
      this.moms = momData.data.result;
    },
    async handleMomChange(momId) {
      this.selectedMom = this.moms.find((item) => item._id.toString() == momId.toString());
    },
    checkAllFieldsEmpty(item) {
      return (
        !item.name &&
        !item.reporterId &&
        !item.assigneeUserIds.length &&
        !item.priorityId &&
        !item.momId &&
        // other fields
        !item.reportToIds.length &&
        !item.statusId &&
        // !item.endDate &&
        // !item.interimDate &&
        // normal fields
        !item.departmentIds.length
      );
    },
    async fetchIssueCreationOptions() {
      this.loading = true;
      try {
        const response = await http.get(`/issue/init`);
        this.issueCreateOptions = response.data;
      } catch (err) {
        console.log("error in loading customers", err);
      }
      this.loading = false;
    },
  },
  watch: {
    startDate(e) {
      if (e) {
        this.minInterimDate = e;
        this.minEndDate = e;
      }
    },
    existingMom(e) {
      if (!e) {
        this.selectedMom = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-text {
  font-size: 20px;
  font-weight: 600;
}
.issue-toggle-btn {
  padding: 10px;
  margin-left: 15px;
  border-radius: 5px;
  font-size: 0.9em;
  cursor: pointer;
  background-color: #f8f8f8;
  &:hover {
    background-color: #eee;
  }
}
.custom-label {
  margin-top: 15px;
  margin-bottom: 5px;
}
.issue-create-table {
  td {
    font-size: 0.8em !important;
  }
  input {
    font-size: 1.2em !important;
  }
  .multiselect-container {
    min-width: 200px;
  }
  border: 1px solid #fff;
}
.issue-create-table-container {
  // max-width: 1000px;
  // overflow-x: scroll;
  // overflow-y: visible;
  //overflow: auto;
}
.tbleheader th {
  border: solid 1px #285adf;
  background: #0d5ce2;
  color: #fff;
}
.tblco td {
  background: #f8f9fa;
  border: solid 1px #f1f3f5;
}
.tblco * {
  border: none;
}
.tblco .interidt {
  margin-right: 7px;
}
.interidt {
  background: #fff;
}
.tblco .my-1 {
  margin-top: 3px !important;
  margin-bottom: 1px !important;
  padding: 0 5px 0 5px !important;
}
</style>
