
import { http } from "@/utils/http"
import TaskStatusViewCard from "./task-status-view-card.vue"

interface IData {
    tasks: any[]
    initialized: boolean
}

export default {
    props: {
        taskStatus: {
            type: Object,
            default: null,
        },
    },
    data(): IData {
        return {
            tasks: [],
            initialized: false,
        }
    },
    components: {
        TaskStatusViewCard,
    },
    watch: {
        taskStatus(e) {
            if (!this.initialized) {
                this.fetchTasks()
            }
        },
    },
    mounted() {
        this.fetchTasks()
    },
    methods: {
        async fetchTasks() {
            if (!this.taskStatus) return
            try {
                const res = await http.post(`/issue/filter?page=${1}&limit=${5}`, {
                    status: { selectedOptions: [this.taskStatus._id] },
                })
                this.tasks = res.data.result
            } catch (err) {
                console.log("error in loading tasks")
            }
            this.initialized = true
        },
    },
}
