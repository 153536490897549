<template>
    <div class="mr-2">
        <img src="@/assets/user-icon.jpg" class="profile-img" alt="" />
    </div>
</template>

<script>
export default {}
</script>

<style>
.profile-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
</style>
