<template>
    <div class="my-3 d-flex">
        <UserIcon />
        <div class="w-full comment-input-container">
            <input
                v-if="!showCommentInput"
                @click="showCommentInput = true"
                class="form-control ml-2"
                type="text"
                placeholder="Add a comment"
            />
            <div v-if="showCommentInput">
                <HtmlEditor :id="`comment-input`" @change="comment = $event" :value="comment" />
                <TagUser
                    :hideSave="true"
                    :createRoute="'/comments'"
                    :updateRoute="commentId ? '/comments/' + commentId : null"
                    :taggedUsers="taggedUsers || []"
                    @change="handleTaggedUsersChange"
                />
                <div class="attachment-manager" v-if="showAttachmentManager">
                    <AttachmentUploader @remove="addToRemoveList('attachment1')" v-model="attachment1" :id="1" :name="attachment1Name" />
                    <AttachmentUploader @remove="addToRemoveList('attachment2')" v-model="attachment2" :id="2" :name="attachment2Name" />
                    <AttachmentUploader @remove="addToRemoveList('attachment3')" v-model="attachment3" :id="3" :name="attachment3Name" />
                </div>
                <div class="my-2 d-flex">
                    <AppBtn @click="handleCommentSave">{{ processingForm ? "Saving..." : "Save" }}</AppBtn>
                    <AppBtn class="mx-2" secondary @click="cancelEditing">Cancel</AppBtn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HtmlEditor from "@/components/other/HtmlEditor"
import AppBtn from "@/components/UI/AppBtn"
import AttachmentUploader from "@/components/other/AttachmentUploader"
import TagUser from "@/components/other/TagUser.vue"
import UserIcon from "./UserIcon.vue"
import { http } from "@/utils/http"

export default {
    data() {
        return {
            commentId: "",
            showCommentInput: false,
            comment: "",
            comments: [],
            loading: false,
            processingForm: false,
            removeFilesList: [],
            attachment1: null,
            attachment2: null,
            attachment3: null,
            attachment1Name: "",
            attachment2Name: "",
            attachment3Name: "",
            showAttachmentManager: true,
            taggedUsers: [],
        }
    },
    props: {
        issueId: { type: String, default: "" },
        commentData: { type: Object, default: () => null },
    },
    components: { HtmlEditor, AppBtn, AttachmentUploader, UserIcon, TagUser },
    mounted() {
        if (this.commentData) {
            this.showCommentInput = true
            this.commentId = this.commentData._id
            this.comment = this.commentData.description || ""
            this.attachment1Name = this.commentData.attachment1
            this.attachment2Name = this.commentData.attachment2
            this.attachment3Name = this.commentData.attachment3
            this.taggedUsers = this.commentData.taggedUsers
        }
    },
    methods: {
        cancelEditing() {
            this.showCommentInput = false
            this.$emit("cancel")
        },
        handleTaggedUsersChange(update) {
            this.taggedUsers = update
        },
        async handleCommentSave() {
            if (this.commentData) {
                this.updateComment()
            } else {
                this.postComment()
            }
        },
        async updateComment() {
            this.postComment(true)
        },
        async postComment(updateMode = true) {
            if (this.processingForm) return
            this.processingForm = true
            try {
                const formData = new FormData()

                formData.append("description", this.comment || "")
                formData.append("issueId", this.issueId)

                // files to remove if any

                // attachments to add if any
                formData.append("attachment1", this.attachment1)
                formData.append("attachment2", this.attachment2)
                formData.append("attachment3", this.attachment3)
                formData.append("taggedUsers", JSON.stringify(this.taggedUsers))

                if (updateMode && this.commentData) {
                    formData.append("removeFilesList", JSON.stringify(this.removeFilesList))
                    const res = await http.patch("/comments/" + this.commentData._id, formData)
                    this.$emit("updated", res.data.result)
                } else {
                    const res = await http.post("/comments", formData)
                    this.$emit("inserted", res.data.result)
                }
                this.showCommentInput = false
                this.comment = ""
            } catch (err) {
                console.log("error in fetching comment", err)
            }
            this.processingForm = false
        },
        async addToRemoveList(item) {
            if (!this.removeFilesList.includes(item)) {
                this.removeFilesList = [...this.removeFilesList, item]
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../crud/form.scss";
.toggle-btn {
    font-weight: bold;
    background-color: #ddd;
    padding: 2px 7px;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 0.9em;

    &.active {
        background-color: #555;
        color: white;
    }
}
.comment-input-container {
    input {
        font-size: 0.8em;
    }
}
.username-text {
    font-weight: bold;
    color: #444;
}
.attachment-manager {
    display: flex;
    gap: 15px;
    flex-flow: row wrap;
}
</style>
