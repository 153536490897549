import { http } from "@/utils/http"

const state = {
    token: "",
    user: null,
    authInitialized: false,
    // role types
    isAdmin: false,
    isSubAdmin: false,
    isUser: false,
}

const getters = {
    authenticated: (state) => {
        return state.token && state.user ? true : false
    },
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
        localStorage.setItem("AUTH_TOKEN", token)
    },
    SET_USER: (state, data) => {
        state.user = data

        // update user role
        state.isAdmin = false
        state.isSubAdmin = false
        state.isUser = false
        const roleName = data && data.roleId && data.roleId.name && data.roleId.name.toLowerCase()

        switch (roleName) {
            case "admin":
                state.isAdmin = true
                break
            case "sub-admin":
                state.isSubAdmin = true
                break
            case "user":
                state.isUser = true
                break
        }
    },
    SET_AUTH_INITIALIZED: (state, data) => {
        state.authInitialized = data
    },
}

const actions = {
    checkRole: (vuexContext, { user, roleToCheck }) => {
        console.log("user, roletocheck", user, roleToCheck)
        if (
            roleToCheck &&
            user &&
            user.roleId &&
            user.roleId.name &&
            user.roleId.name.toLowerCase() === roleToCheck.toLowerCase()
        ) {
            return true
        }

        console.log(
            "result of check role user.roleId.name.toLowerCase() === roleToCheck.toLowerCase()",
            user.roleId && user.roleId.name.toLowerCase() === roleToCheck.toLowerCase()
        )
        return false
    },
    login: async ({ commit }, formData) => {
        let response = await http.post("/auth/login-app", formData)
        console.log("formData", formData)
        const result = response.data.result

        //  (response.data.success === true)
        commit("SET_TOKEN", result.token)
        commit("SET_USER", result.user)

        return
    },
    initAuth: async ({ commit, dispatch }) => {
        const token = localStorage.getItem("AUTH_TOKEN")
        if (token) {
            try {
                let response = await http.get("/auth/me-app")
                const result = response.data.result

                dispatch("project/initIssues", null, { root: true })
                commit("SET_TOKEN", token)
                commit("SET_USER", result)
            } catch (error) {
                console.log("caught error login", error)
            }
        }
        commit("SET_AUTH_INITIALIZED", true)
    },
    logout: async ({ commit }) => {
        commit("SET_TOKEN", "")
        commit("SET_USER", null)
        localStorage.clear()
    },
}

export default {
    namespaced: true,

    state,
    getters,
    actions,
    mutations,
}
