<template>
    <button @click="$emit('click')" :class="['app-btn border-none', { 'my-3 mt-4': !noMargin }, { disabled }]">
        <slot />
    </button>
</template>

<script>
export default {
    props: {
        noMargin: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
}
</script>

<style lang="scss" scoped>
.app-btn {
    font-size: 0.9em;
    color: white;
    padding: 10px;
    border-radius: 10px;
    min-width: 120px;
    background-color: #ddd;
    &:hover {
        color: white;
        background-color: lighten(#ddd, 15%);
    }
    &.disabled {
        opacity: 0.4;
    }
}
</style>
